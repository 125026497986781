
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainer-list',
  components: {
    Form,
    Datatable,
    SearchBar,
    Swal,
    Multiselect,
  },
  data() {
    return {
      api_url: '',
      load: false,
      loading: false,
      tranche: [] as any,
      association: [] as any,
      TrancheInfos:[] as any,
      optionsTranche:[] as any,
      btnCheck: false,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '200px',
        },
        {
          name: 'Unique Course',
          key: 'activity_report',
          sortable: true,
        },
        {
          name: 'Code',
          key: 'code',
          sortable: true,
        },
        {
          name: 'Name of industry Association / Govt. Org',
          key: 'association',
          sortable: true,
          width: '300px',
        },
        {
          name: 'Course Found',
          key: 'course',
          sortable: true,
        },
      ],
      tranche_id: [] as any,
      TpartnerInfos:[] as any,
      optionsTPartner:[] as any,
      entity_id: [] as any,
      course_type: '' as any,
      start_date: '' as any,
      end_date: '' as any,
      showActivityReport: false,
      componentKey: 0,
      actionActive: false,
      activityReport: [] as any,
      selectAll: {
        id: 'all',
        label: 'All',
      } as any,
      TotalTrainee: 0 as any,
      Totalenrollment: 0 as any,
      Totalfemale: 0 as any,
      Totalcertification: 0 as any,
      TotalcertificationFemale: 0 as any,
      Totalcertification_percentage: 0 as any,
      total_job_placement: 0 as any,
      total_female_job_placement: 0 as any,
      Totaljob_placement_percentage: 0 as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
    await this.getAssociation();
  },
  methods: {
      async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
    async courseActivityReport() {
      this.load = true;
      await ApiService.get(
        'course/activityReport?tranche=' +
          this.tranche_id +
          '&entity=' +
          this.entity_id +
          '&type=' +
          this.course_type +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
      )
        .then((response) => {
          this.activityReport = response.data.data.coursedata;
          this.TotalTrainee = response.data.data.TotalTrainee;
          this.Totalenrollment = response.data.data.Totalenrollment;
          this.Totalfemale = response.data.data.Totalfemale;
          this.Totalcertification = response.data.data.Totalcertification;
          this.TotalcertificationFemale =
            response.data.data.TotalcertificationFemale;
          this.Totalcertification_percentage =
            response.data.data.Totalcertification_percentage;
          this.total_job_placement = response.data.data.total_job_placement;
          this.total_female_job_placement =
            response.data.data.total_female_job_placement;
          this.Totaljob_placement_percentage =
            response.data.data.Totaljob_placement_percentage;
          this.showActivityReport = true;
          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async btnCheckStatus() {
      if (this.tranche_id && this.course_type) {
        this.btnCheck = true;
      } else {
        Swal.fire({
          title: 'Tranche and Training Type Need to be Select!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete!',
        });
      }
      // else popup
    },
    view(data) {
      // console.log(data);
      this.emitter.emit('course_unique_details', data);
    },

      async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    // async actioncheck() {
    //   let menu = JSON.parse(localStorage.getItem("menu") || "{}");
    //   if (menu) {
    //     for (let i = 0; i < menu.length; i++) {
    //       //console.log(menu[i].action);
    //       if (menu[i].heading == "Trainer") {
    //         //console.log(menu[i].action);
    //         let actions = menu[i].action;
    //         //console.log(actions);
    //         for (let j = 0; j < actions.length; j++) {
    //           if (actions[j].action_name === "Edit") {
    //             // console.log(actions[j].action_name);
    //             this.actionEdit = true;
    //           }
    //           if (actions[j].action_name === "Delete") {
    //             // console.log(actions[j].action_name);
    //             this.actionDelete = true;
    //           }
    //           if (actions[j].action_name === "Approve") {
    //             // console.log(actions[j].action_name);
    //             this.actionActive = true;
    //           }
    //         }
    //       }
    //     }
    //   }
    // },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
